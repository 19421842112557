<template>
  <div class="feedback-view">
    <div class="message-container">
      <img class="user-waiting-img -mb_2"
           :src="require('@/assets/feedbacks/user-waiting.svg')"
           alt="user waiting authentification">

      <p class="fc-blue-8">Your session has expired.</p>
      <template>
        <p class="-mt_0 fc-blue-8">Sign in again on SWORD Web Portal</p>

        <sword-button-wuk class="-mt_3"
                          text="Sign in"
                          type="primary"
                          size="small"
                          @click.native="goToPortal"/>
      </template>
    </div>

    <div class="bottom-annotations-container -pb_2">
      <a class="swordhealth-website-link t4 fc-blue-4"
         rel="noopener noreferrer"
         :href="redirectUrl"
         target="_blank">
        SWORDHEALTH.COM
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import configs from '@/configs';

import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue';

export default {
  name: 'Error',
  components: {
    SwordButtonWuk,
  },
  computed: {
    ...mapGetters({
      gotNewSession: 'user/cookies/haveAuthCookies',
    }),
    redirectUrl() {
      return `${configs.get('CLINICAL_PORTAL_LOGIN_URL')}?redirect=${window.location.href}`;
    },
  },
  mounted() {
      document.addEventListener('visibilitychange', async () => {
      console.log('[bug-track] user changed tab');
      if (!document.hidden) {
        console.log('[bug-track] Chat tab got seleted');
        await this.checkForNewCookies(false);
        if (this.gotNewSession) {
          this.backToChat();
          return;
        }
          console.log('[bug-track] Cookies not found, not redirecting from logout screen.');
      }
    });
  },
  methods: {
    ...mapActions({
      checkForNewCookies: 'user/handleCookieAuth',
    }),
    backToChat() {
      this.$router.replace({ name: 'Chat' });
    },
    goToPortal() {
      window.location.href = this.redirectUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

  .user-waiting-img {
    width: 15rem;
    max-width: 80%;
    margin: 0 auto;
  }

  .feedback-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .message-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    flex-grow: 1;
    max-width: 90%;
    text-align: center;
    align-items: center;
  }

  .bottom-annotations-container {
    text-align: center;
  }

  .swordhealth-website-link {
    display: block;
    text-decoration: none;
  }
</style>
